export const ListButtons = [
    {
        text: "Levels",
        pathTo: "/ChooseLevel",
        color: "rgba(209, 37, 37, 1)",
    },
    {
        text: "Home",
        pathTo: "/",
        color: "#8e00c4",
    },
    {
        text: "Select Team",
        pathTo: "/SelectTeam",
        color: "#020de5",
    },
];
